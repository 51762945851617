import React from "react";

const Dashboard = () => {
  return (
    <div>
      <h1>Welcome to your dashboard!</h1>
      <p>You are now logged in and have access to this protected page.</p>
    </div>
  );
};

export default Dashboard;
