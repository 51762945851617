import headerImg from "../../assets/herron-header1440x600.png";
import "./Home.css";
import { TopNav, Footer } from "../../components";

const Home = () => {
  return (
    <>
      <TopNav />
      <main>
        <div className="container">
          <div id="headerImage">
            <img
              src={headerImg}
              alt="Herron Publishing Header Image"
              aria-hidden="true"
            />
          </div>

          <div id="welcome">
            <h1>Welcome</h1>
            <p>
              Welcome to Herron Publishing, a leader in mass market publishing
              for the domestic and international trade markets! Since our
              establishment in 1980, we have grown to become one of the largest
              distributors in Australia, and we have built a reputation for
              publishing unique, high-quality titles at unbelievable prices.
            </p>
            <p>
              As publishing disruptors in the non-fiction market, we are
              committed to delivering titles that inspire, educate, and
              entertain our readers. Our key categories include Cooking,
              Mindfulness, Homestyle, Travel, and Puzzles, and we are proud to
              work with some of the biggest retailers in the world.
            </p>
            <p>
              At Herron Publishing, we believe that our success lies in our
              ability to deliver exceptional service to our customers. We are
              dedicated to working closely with our clients to understand their
              needs and to provide tailored solutions that meet those needs. Our
              team of experienced professionals is committed to providing
              personalised support and guidance to help our clients achieve
              their publishing goals.
            </p>
            <p>
              We take pride in our ability to produce titles that stand out in
              the market, and we are always looking for new and innovative ways
              to bring our readers the best possible products. Whether you are a
              retailer or a reader, we invite you to explore our selection of
              titles and to experience the quality and value that Herron
              Publishing has to offer.
            </p>
            <p>
              Thank you for considering Herron Publishing as your publishing
              partner. We look forward to working with you and to continuing to
              grow and innovate in the years ahead.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
