import "./Faq.css";
import { questions } from "./data.js";
import Question from "./Question";
import { BsPatchQuestion } from "react-icons/bs";

import { TopNav, Footer } from "../../components";

const Faq = () => {
  return (
    <>
      <TopNav />
      <main id="faq">
        <div className="container">
          <div id="faqtop">
            <BsPatchQuestion color="#000" size={30} />
            <h1>FAQs</h1>
            <p>
              Here are a few of the commonly asked question that you might like
              know.
            </p>
          </div>
          <div id="questions">
            {questions.map((question) => (
              <Question
                key={question.id}
                title={question.title}
                answer={question.answer}
                note={question.note}
              />
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Faq;
