import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from "@aws-amplify/ui-react";
import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import logo from "../../assets/logo.svg";
import "./TopNav.css";

const TopNav = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate("/");
  }

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav>
      <a href="/">
        <div id="logo">
          <img src={logo} alt="Herron Publishing" />
          <h1 id="logo-text">HERRON Publishing</h1>
        </div>
      </a>
      <div className="menu">
        <ul id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}>
          <li>
            <a href="/books">BOOKS</a>
          </li>
          <li>
            <a href="/faq">FAQ</a>
          </li>
          {route !== "authenticated" ? (
            <li className="nav-btn">
              <Button onClick={() => navigate("/admin")}>Login</Button>
            </li>
          ) : (
            <>
              <li>
                <a href="/admin">ADMIN</a>
              </li>
              <li className="nav-btn">
                <Button onClick={() => logOut()}>Logout</Button>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="menu-icons" onClick={toggleMenu}>
        {showMenu ? (
          <RiCloseLine color="rgb(002, 071, 049)" size={30} />
        ) : (
          <AiOutlineBars color="rgb(002, 071, 049)" size={30} />
        )}
      </div>
    </nav>
  );
};

export default TopNav;
