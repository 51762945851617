import animals from "../../assets/herron-animals-pets.png";
import cookbooks from "../../assets/herron-cookbooks.png";
import homestyle from "../../assets/herron-homestyle.png";
import journal from "../../assets/herron-journal.png";
import selfCare from "../../assets/herron-self-care.png";
import travel from "../../assets/herron-travel.png";
import { GiBlackBook } from "react-icons/gi";
import "./Books.css";

import { CatCard, TopNav, Footer } from "../../components";

const Books = () => {
  return (
    <>
      <TopNav />
      <main id="books">
        <div className="container">
          <div id="bookstop">
            <GiBlackBook color="#000" size={30} />
            <h1>BOOKS</h1>
            <p>
              We have published hundreds of book. Here are the main categories.
              Please contact us if you want a list of current available titles.
            </p>
          </div>
          <div id="catagories">
            <CatCard
              srcImg={animals}
              altTxt="Herron Publishing Animal and Pet Books"
              title="Animals and Pets"
            />
            <CatCard
              srcImg={cookbooks}
              altTxt="Herron Publishing Cookbooks"
              title="Cookbooks"
            />
            <CatCard
              srcImg={homestyle}
              altTxt="Herron Publishing Homestyle Books"
              title="Homestyle"
            />
            <CatCard
              srcImg={journal}
              altTxt="Herron Publishing Journal Books"
              title="Journals"
            />
            <CatCard
              srcImg={selfCare}
              altTxt="Herron Publishing Self Care Books"
              title="Self care"
            />
            <CatCard
              srcImg={travel}
              altTxt="Herron Publishing Travel Books"
              title="Travel"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Books;
