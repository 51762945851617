import "./CatCard.css";

const CatCard = ({ srcImg, altTxt, title }) => {
  return (
    <div className="category">
      <div className="catImage">
        <img src={srcImg} alt={altTxt} aria-hidden="true" />
      </div>
      <div className="title">
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default CatCard;
