export const questions = [
  {
    id: 1,
    title: "Do you publish for external authors?",
    answer:
      "No. We have our own team that authors for us and all our publications are ours.",
    note: "We publish our own books.",
  },
  {
    id: 2,
    title: "What countries can you supply?",
    answer:
      "We can supply anywhere from our warehouse or directly from our printers.",
    note: "We supply globally - anywhere.",
  },
];
