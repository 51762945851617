import { Authenticator } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";
import { TopNav, Footer } from "../../components";
import "./Admin.css";

const Admin = () => {
  return (
    <div id="admin">
      <TopNav />
      <main>
        <Authenticator>
          <div id="adminPannel">
            <h1>Admin Page</h1>
            <Outlet />
          </div>
        </Authenticator>
      </main>
      <Footer />
    </div>
  );
};

export default Admin;
