import React from "react";
import ReactDOM from "react-dom/client";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";

import "./index.css";
import "@aws-amplify/ui-react/styles.css";

//setup route
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//define the page
import { Admin, Books, Dashboard, ErrorPage, Faq, Home } from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/books",
    element: <Books />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
    ],
  },
]);

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Authenticator.Provider>
    <RouterProvider router={router} />
  </Authenticator.Provider>
);
