import React from "react";
import "./Footer.css";

import logo from "../../assets/logo.svg";

import { FaMapMarkerAlt, FaPhoneAlt, FaFax, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div id="footer-main">
          <div id="footer-left">
            <img src={logo} alt="Herron Publishing" aria-hidden="true" />
            <p>
              &copy; Copyright {new Date().getFullYear()}. Herron Book
              Distributors
            </p>
            <hr />
          </div>
          <div id="footer-right">
            <div id="footer-contact">
              <div className="footer-row">
                <div className="footer-row-left">
                  <p>
                    <FaMapMarkerAlt /> &nbsp;Address:
                  </p>
                </div>
                <div className="footer-row-right">
                  <p id="address">
                    14 Manton Street
                    <br />
                    Morningside QLD 4170
                    <br />
                    AUSTRALIA
                  </p>
                </div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <p>
                    <FaPhoneAlt /> &nbsp; Phone:
                  </p>
                </div>
                <div className="footer-row-right">
                  <p>+61 7 33998799</p>
                </div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <p>
                    <FaFax /> &nbsp; Fax:
                  </p>
                </div>
                <div className="footer-row-right">
                  <p>+61 7 3399 3255</p>
                </div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <p>
                    <FaEnvelope /> &nbsp; Email:
                  </p>
                </div>
                <div className="footer-row-right">
                  <p>sales@herronbooks.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
